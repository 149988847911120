import React, { useContext, useState } from 'react';
import Title from '../symbols/app/pageTitle/Title';
import PageIndication from '../symbols/app/pageIndication/PageIndication';
import PageSection from '../symbols/app/pageSection/PageSection';
import ButtonSecondary from '../symbols/app/button/ButtonSecondary';
import { navigate } from '@reach/router';
import { FormContext } from '../context/FormContext';
import { httpPost } from '../modules/backend';
import ErrorMessage from '../symbols/app/error/errorMessage';
import LoadingModal from '../symbols/app/loading/LoadingModal';

export default function PropertyOwners ({ }) {
    const { exportContacts, quoteId } = useContext(FormContext);
    const [error, setError] = useState();
    const [submitting, setSubmitting] = useState(false);


    async function completeQuote(){
        //submit contacts to back end then go to success page
        setSubmitting(true)
        await httpPost('quoteenquiry/accept',
            {
                quoteId: quoteId,
                contacts: exportContacts()
            }).then(async function (response) {
                if (response.data.successful) {
                    navigate('/success')
                } else {
                    setError(response.data.error);
                    setSubmitting(false)
                }
            }).catch(function (error) {
                setError(["Could not accept quote."]);
                setSubmitting(false)
            });
    }

    function addMoreContacts(){
        navigate('/aboutOthers')
    }



    return <>
        {submitting &&
            <LoadingModal/>
        }
        <div className={`property-owners__content padding__left-right--xs margin__left-right--auto`}>
            <Title />
            <PageIndication activePage={"AboutYou"} />
            <PageSection>
                <p className={`property-owners__text`}>Will other people be involved in this transaction <br/>(joint property owners)</p>
                <div className={`property-owners__button-wrapper`}>
                <ButtonSecondary text="Yes" onClick={addMoreContacts} name={"addMoreContactsBtn"}/>
                <ButtonSecondary text="No" onClick={completeQuote} name={"completeBtn"}/>
                </div>
            </PageSection>
            <ErrorMessage errorMessages={error}/>
        </div>
    </>
}