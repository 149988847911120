import React, { useEffect, useState, useContext } from 'react';
import Title from '../symbols/app/pageTitle/Title';
import PageIndication from '../symbols/app/pageIndication/PageIndication';
import PageSection from '../symbols/app/pageSection/PageSection';
import FormInputText from '../symbols/app/form/FormInputText';
import FormInputRadio from '../symbols/app/form/FormInputRadio';
import { FormContext } from '../context/FormContext';
import {
    NotEmptyString,
    ValidateEmail,
} from '../modules/Validation';
import PrimaryButton from '../symbols/app/button/ButtonPrimary';
import ButtonSecondary from '../symbols/app/button/ButtonSecondary';
import { navigate } from '@reach/router';
import ErrorMessage from '../symbols/app/error/errorMessage'

export default function AboutYou() {
    const {contacts, setContacts, quoteId, setCanNavigateAboutYou } = useContext(FormContext);
    const [canContinue, setCanContinue] = useState(false);
    const [error, setError] = useState();

    const handleChangeTitle = (event, i) => {
        event.persist();
        let items = [...contacts];
        let contact = {...items[i]};
        contact.title = event.target.value;
        items[i] = contact;
        setContacts(items);
    };

    const handleChangeOtherTitle = (event, i) => {
        event.persist();
        let items = [...contacts];
        let contact = {...items[i]};
        contact.otherTitle = event.target.value;
        items[i] = contact;
        setContacts(items);
    };

    const handleChangeFirstName = (event, i) => {
        event.persist();
        let items = [...contacts];
        let contact = {...items[i]};
        contact.firstName = event.target.value;
        items[i] = contact;
        setContacts(items);
    };

    const handleChangeMiddleName = (event, i) => {
        event.persist();
        let items = [...contacts];
        let contact = {...items[i]};
        contact.middleName = event.target.value;
        items[i] = contact;
        setContacts(items);
    };

    const handleChangeLastName = (event, i) => {
        event.persist();
        let items = [...contacts];
        let contact = {...items[i]};
        contact.lastName = event.target.value;
        items[i] = contact;
        setContacts(items);
    };

    const handleChangeEmail = (event, i) => {
        event.persist();
        event.persist();
        let items = [...contacts];
        let contact = {...items[i]};
        contact.email = event.target.value;
        items[i] = contact;
        setContacts(items);
    };

    function validate(){
        setError();
        var errorList = document.getElementsByClassName("Validation_Failed");
        if(errorList.length>0){
            setError(["Please fix any errors in the form."]);
            return false;
        }else{
            return true;
        } 
    }

    function next() {
        if(validate()){
            navigate('/propertyOwners')
        }
        
    }

    function addMainContact(){
        setContacts([{
            isMainContact: true,
            title:"",
            otherTitle:"",
            firstName:"",
            middleName:"",
            lastName:"",
            email:"",
        }])
    }

    function checkCanContinue(){
        var result = true;
        contacts.forEach(contact => {
            if(contact.firstName === "" || contact.lastName === "" || contact.email === "" || !ValidateEmail(contact.email)){
                result = false;
            }
        });
        setCanContinue(result);
    }
    useEffect(() => {
        setCanNavigateAboutYou(true)
        addMainContact()
    }, []);

    useEffect(() => {
        checkCanContinue()
    }, [contacts]);

    function navigateBack() {
        navigate(`/YourQuote/${quoteId}`)
    }


    return <div className={`about-you__content padding__left-right--xs margin__left-right--auto`}>
        <Title />
        <PageIndication activePage={"AboutYou"} />
        <div>


        {contacts &&
                contacts.filter(item => item.isMainContact === true).map((contact, i) =>
                    <PageSection sectionTitle={"About you"} key={i}>
                        <p className={`about-you__radio-group-label`}>Title</p>
                        <div className={`grid-x grid-margin-x grid-margin-y about-you__radio-group-wrapper`}>
                            <div className={'cell small-12 medium-6 large-4'}>
                                <FormInputRadio  label="Mr" name={`title-${i}`} id={`Mr-${i}`} onChange={event => handleChangeTitle(event, i)} value={"Mr"} checked={contact.title === "Mr"}/>
                            </div>
                            <div className={'cell small-12 medium-6 large-4'}>
                                <FormInputRadio label="Mrs" name={`title-${i}`} id={`Mrs-${i}`} onChange={event => handleChangeTitle(event, i)} value={"Mrs"} checked={contact.title === "Mrs"}/>
                            </div>
                            <div className={'cell small-12 medium-6 large-4'}>
                                <FormInputRadio label="Miss" name={`title-${i}`} id={`Miss-${i}`} onChange={event => handleChangeTitle(event, i)} value={"Miss"} checked={contact.title === "Miss"}/>
                            </div>
                            <div className={'cell small-12 medium-6 large-4'}>
                                <FormInputRadio label="Ms" name={`title-${i}`} id={`Ms-${i}`} onChange={event => handleChangeTitle(event, i)} value={"Ms"} checked={contact.title === "Ms"}/>
                            </div>
                            <div className={'cell small-12 large-4'}>
                                <FormInputRadio  label="Other" name={`title-${i}`} id={`Other-${i}`} onChange={event => handleChangeTitle(event, i)} value={"Other"} checked={contact.title === "Other"}/>
                            </div>
                        </div>
                        {contact.title === "Other" &&
                            <FormInputText label="Enter your title" name={`otherTitle-${i}`} onChange={event => handleChangeOtherTitle(event, i)} value={contact.otherTitle || ''}/>
                        }
                        <FormInputText label="First name" name={`firstName-${i}`} onChange={event => handleChangeFirstName(event, i)} value={contact.firstName || ''} required validateRule={NotEmptyString} validationError={"Please enter your first name"}/>
                        <FormInputText label="Middle name" name={`middleName-${i}`} onChange={event => handleChangeMiddleName(event, i)} value={contact.middleName || ''} />
                        <FormInputText label="Last name" name={`lastName-${i}`} onChange={event => handleChangeLastName(event, i)} value={contact.lastName || ''} required validateRule={NotEmptyString} validationError={"Please enter your last name"}/>
                        <FormInputText label="Email address" name={`email-${i}`} onChange={event => handleChangeEmail(event, i)} value={contact.email || ''} required validateRule={ValidateEmail} validationError={"Please enter a valid email"}/>
                    </PageSection>
                )  
            }

            <ErrorMessage errorMessages={error}/>

            <div className={`about-you__button-wrapper padding__bottom--l`}>
                <ButtonSecondary text="Back" onClick={navigateBack} />
                <PrimaryButton text={"Next"} onClick={next} name="nextBtn" disabled={!canContinue}/>
            </div>
        </div>
    </div>
}