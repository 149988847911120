import React, { useEffect, useState } from 'react';
import { httpGet } from '../modules/backend';
import Title from '../symbols/app/pageTitle/Title';
import { navigate } from '@reach/router';
import ButtonSecondary from '../symbols/app/button/ButtonSecondary';

export default function TermsAndConditions({ quoteId, includeContactDetails }) {

    const [termsAndConditions, setTermsAndConditions] = useState('');

    function navigateBack() {
        navigate(`/yourQuote/${quoteId}`)
    }

    useEffect(() => {
        async function get() {
            const result = await httpGet(`termsandconditions?quoteId=${quoteId}&&includeContactDetails=${includeContactDetails}`);
            setTermsAndConditions(result.data);
        }
        get()
    }, [])


    return <div className={`terms-conditions__full-screen-wrapper`}>
        {termsAndConditions &&
            <div className={`terms-conditions__content padding__left-right--xs margin__left-right--auto`}>
                <Title />
                {termsAndConditions &&
                    <>
                        <div className={`terms-conditions__text-wrapper`} dangerouslySetInnerHTML={{ __html: termsAndConditions }}  id="termsAndConditions"></div>
                        <div className={`terms-conditions__button-wrapper padding__top-bottom--l`}>
                            <ButtonSecondary text="Back" onClick={navigateBack} />
                        </div>
                    </>
                }
            </div>
        }


    </div>
}