import React from 'react';

export default function QuoteItem({className, title, prefix, amount, dark}) {

	function numberWithCommas(x) {
		if(x){
			return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		}
		return "";
	}

	return <div className={`quote__item__wrapper padding__top-bottom--xs padding__left-right--s margin__bottom--xs  ${dark === true ? "quote__item-dark-background" : "quote__item-light-background"} ${className ? className : ''}`}>
		<p className={`quote__item__text quote__item__bold ${dark === true ? "quote__item-dark-text" : "quote__item-light-text "} margin__right--xs`} dangerouslySetInnerHTML={{__html: title}}></p>
        <p className={`quote__item__text ${dark === true ? "quote__item-dark-text" : "quote__item-light-text "}`}>{prefix}{numberWithCommas(amount)}</p>
	</div>
}