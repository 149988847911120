import React, { useContext } from 'react';
import Title from '../symbols/app/pageTitle/Title';
import PageIndication from '../symbols/app/pageIndication/PageIndication';
import PageSection from '../symbols/app/pageSection/PageSection';
import {FormContext} from '../context/FormContext';
import FormInputRadio from '../symbols/app/form/FormInputRadio';
import PrimaryButton from '../symbols/app/button/ButtonPrimary';
import { navigate } from '@reach/router';

export default function PropertyDetails() {
    const {
        saleType,
        setSaleType,
        clearAllValues
    } = useContext(FormContext);


    const handleChangeSaleType = (event) => {
        event.persist();
        setSaleType(event.target.value);
        clearAllValues();
    };



    function next(){
        navigate('/propertyDetails')
    }


    return <div className={`property-details__content padding__left-right--xs margin__left-right--auto`}>
        <Title />
        <PageIndication activePage={"PropertyDetails"}/>
        <form>
            <PageSection sectionTitle={"Property Details"}>
                <p>Are you selling or buying a property?</p>
                <div className={`grid-x grid-margin-x grid-margin-y`}>
                <div className={'cell small-12 medium-4'}>
                        <FormInputRadio className={`Sale_Type_Radio`} label="Selling" name="saleType" id={"Selling"} onChange={handleChangeSaleType} value={"Selling"} checked={saleType === "Selling"}/>
                    </div>
                    <div className={'cell small-12 medium-4'}>
                        <FormInputRadio className={`Sale_Type_Radio`} label="Buying" name="saleType" id={"Buying"} onChange={handleChangeSaleType} value={"Buying"} checked={saleType === "Buying"}/>
                    </div>
                    <div className={'cell small-12 medium-4'}>
                        <FormInputRadio className={`Sale_Type_Radio`} label="Selling and buying" name="saleType" id={"BuyingAndSelling"} onChange={handleChangeSaleType} value={"BuyingAndSelling"} checked={saleType === "BuyingAndSelling"}/>
                    </div>
                </div>
            </PageSection>
        </form>

        <div className={`property-details__footer-button-wrapper margin__top-bottom--s`}>
            <PrimaryButton name="nextBtn" text={"Next"} onClick={next} disabled={saleType === ""}/>
        </div>
    </div>
}