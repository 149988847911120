import React, { useContext, useEffect, useState } from 'react';
import Title from '../symbols/app/pageTitle/Title';
import PageIndication from '../symbols/app/pageIndication/PageIndication';
import PageSection from '../symbols/app/pageSection/PageSection';
import PrimaryButton from '../symbols/app/button/ButtonPrimary';
import ButtonSecondary from '../symbols/app/button/ButtonSecondary';
import { navigate } from '@reach/router';
import { FormContext } from '../context/FormContext';
import IconEdit from '../symbols/app/icon/IconEdit';
import FormInputText from '../symbols/app/form/FormInputText';
import FormInputRadio from '../symbols/app/form/FormInputRadio';
import {
    NotEmptyString,
    ValidateEmail,
} from '../modules/Validation';
import { httpPost } from '../modules/backend';
import Modal from '../symbols/app/modal/Modal';
import ErrorMessage from '../symbols/app/error/errorMessage';
import LoadingModal from '../symbols/app/loading/LoadingModal';


export default function AboutOther({ }) {
    const { contacts, setContacts, exportContacts, quoteId } = useContext(FormContext);
    const [error, setError] = useState();
    const [showModal, setShowModal] = useState(false);
    const [contactToEdit, setContactToEdit] = useState();
    const [contactToEditIndex, setContactToEditIndex] = useState();
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        addContact(0)

        //this is to scroll in side bar to the top keeping it fixed under the header which is 79px height
        window.onscroll = function () {
            if (window.innerWidth < 1400) {
                return;
            }
            if (window.pageYOffset < 80) {
                if (document.getElementById("about-others__contacts-wrapper") != null) {
                    document.getElementById("about-others__contacts-wrapper").style.top = `${(79 - window.pageYOffset)}px`
                    document.getElementById("about-others__contacts-wrapper").style.height = `${(window.innerHeight - (79-window.pageYOffset))}px`
                }

            } else {
                if (document.getElementById("about-others__contacts-wrapper") != null) {
                    document.getElementById("about-others__contacts-wrapper").style.top = `0px`
                    document.getElementById("about-others__contacts-wrapper").style.height = `100vh`
                }
            }
        };
    }, [])


    function editContact(contactToEdit, i) {
        setContactToEdit(contactToEdit)
        setContactToEditIndex(i)
        setShowModal(true);
    }

    function back() {
        //remove any added contacts 
        //leave just main contact
        let items = [...contacts];
        let justMainContact = items.filter(item => item.isMainContact);
        setContacts(justMainContact);

        navigate(`/propertyOwners/`)
    }

    async function complete() {
        
        removeEmptyContacts();
        if (checkCanContinue() && validate()) {
            setSubmitting(true)
            await httpPost('quoteenquiry/accept',
                {
                    quoteId: quoteId,
                    contacts: exportContacts()
                }).then(async function (response) {
                    if (response.data.successful) {
                        navigate('/success')
                    } else {
                        setError(response.data.error);
                        setSubmitting(false)
                    }
                }).catch(function (error) {
                    setError([`Could not save quote.`]);
                    setSubmitting(false)
                });
        } else {
            setError(["Please fill in all fields in the form."]);
        }

    }

    function validate() {
        setError();
        var errorList = document.getElementsByClassName("Validation_Failed");
        if (errorList.length > 0) {
            setError(["Please fix any errors in the form."]);
            return false;
        } else {
            return true;
        }
    }

    function removeEmptyContacts(){
        contacts.forEach(function(contact, index, array) {
            if (contact.firstName === "" && contact.lastName === "" && contact.email === "" || contact.email === "") {
                array.splice(index, 1);
            }
        });
    }

    function checkCanContinue() {
        var result = true;
        contacts.forEach(contact => {
            if (contact.firstName === "" || contact.lastName === "" || contact.email === "" || !ValidateEmail(contact.email)) {
                result = false;
            }
        });
        return result;
    }

    const handleChangeTitle = (event, i) => {
        event.persist();
        let items = [...contacts];
        let contact = { ...items[i] };
        contact.title = event.target.value;
        items[i] = contact;
        setContacts(items);
    };

    const handleChangeOtherTitle = (event, i) => {
        event.persist();
        let items = [...contacts];
        let contact = { ...items[i] };
        contact.otherTitle = event.target.value;
        items[i] = contact;
        setContacts(items);
    };

    const handleChangeFirstName = (event, i) => {
        event.persist();
        let items = [...contacts];
        let contact = { ...items[i] };
        contact.firstName = event.target.value;
        items[i] = contact;
        setContacts(items);
    };

    const handleChangeMiddleName = (event, i) => {
        event.persist();
        let items = [...contacts];
        let contact = { ...items[i] };
        contact.middleName = event.target.value;
        items[i] = contact;
        setContacts(items);
    };

    const handleChangeLastName = (event, i) => {
        event.persist();
        let items = [...contacts];
        let contact = { ...items[i] };
        contact.lastName = event.target.value;
        items[i] = contact;
        setContacts(items);
    };

    const handleChangeEmail = (event, i) => {
        event.persist();
        event.persist();
        let items = [...contacts];
        let contact = { ...items[i] };
        contact.email = event.target.value;
        items[i] = contact;
        setContacts(items);
    };

    function addContact(i) {
        if (checkCanContinue()) {
            let items = [...contacts];
            let contact = { ...items[i] };
            contact.completed = true;
            items[i] = contact;


            setContacts([...items, {
                isMainContact: false,
                title: "",
                otherTitle: "",
                firstName: "",
                middleName: "",
                lastName: "",
                email: "",
                completed: false
            }])
        }

    }

    function closeModal() {
        setShowModal(false);
    }

    const handleEditContactChange = (event) => {
        event.persist();
        setContactToEdit(contactToEdit => ({ ...contactToEdit, [event.target.name]: event.target.value }));
    };

    function saveEditedContact() {
        let items = [...contacts];
        items[contactToEditIndex] = contactToEdit;
        setContacts(items);
        closeModal();
    }


    return <>
        {submitting &&
            <LoadingModal/>
        }
        <div className={`about-others__content padding__left-right--xs margin__left-right--auto`}>
            {showModal &&
                <Modal onClose={closeModal}>
                    <h1>Edit person {contactToEditIndex === 0 ? "one" : ""}{contactToEditIndex === 1 ? "two" : ""}{contactToEditIndex === 2 ? "three" : ""}</h1>
                    <p className={`margin__bottom--xxs`}>Title</p> 
                    <div className={`grid-x grid-margin-x grid-margin-y margin__bottom--s`}>
                        <div className={'cell small-12 medium-6 large-4'}>
                            <FormInputRadio label="Mr" name={`title`} id={`Mr`} onChange={event => handleEditContactChange(event)} value={"Mr"} checked={contactToEdit.title === "Mr"} />
                        </div>
                        <div className={'cell small-12 medium-6 large-4'}>
                            <FormInputRadio label="Mrs" name={`title`} id={`Mrs`} onChange={event => handleEditContactChange(event)} value={"Mrs"} checked={contactToEdit.title === "Mrs"} />
                        </div>
                        <div className={'cell small-12 medium-6 large-4'}>
                            <FormInputRadio label="Miss" name={`title`} id={`Miss`} onChange={event => handleEditContactChange(event)} value={"Miss"} checked={contactToEdit.title === "Miss"} />
                        </div>
                        <div className={'cell small-12 medium-6 large-4'}>
                            <FormInputRadio label="Ms" name={`title`} id={`Ms`} onChange={event => handleEditContactChange(event)} value={"Ms"} checked={contactToEdit.title === "Ms"} />
                        </div>
                        <div className={'cell small-12 large-4'}>
                            <FormInputRadio label="Other" name={`title`} id={`Other`} onChange={event => handleEditContactChange(event)} value={"Other"} checked={contactToEdit.title === "Other"} />
                        </div>
                    </div>
                    {contactToEdit.title === "Other" &&
                        <FormInputText label="Enter your title" name={`otherTitle`} onChange={event => handleEditContactChange(event)} value={contactToEdit.otherTitle || ''} />
                    }
                    <FormInputText label="First name" name={`firstName`} onChange={event => handleEditContactChange(event)} value={contactToEdit.firstName || ''} required validateRule={NotEmptyString} validationError={"Please enter your first name"} />
                    <FormInputText label="Middle name" name={`middleName`} onChange={event => handleEditContactChange(event)} value={contactToEdit.middleName || ''} />
                    <FormInputText label="Last name" name={`lastName`} onChange={event => handleEditContactChange(event)} value={contactToEdit.lastName || ''} required validateRule={NotEmptyString} validationError={"Please enter your last name"} />
                    <FormInputText label="Email address" name={`email`} onChange={event => handleEditContactChange(event)} value={contactToEdit.email || ''} required validateRule={ValidateEmail} validationError={"Please enter a valid email"} />
                    <div className={`about-others__modal__button-wrapper`}>
                        <ButtonSecondary text="Cancel" onClick={closeModal} />
                        <PrimaryButton text={"Submit"} onClick={saveEditedContact} name={"saveEditedContactBtn"} />
                    </div>
                </Modal>
            }

            <Title />
            <PageIndication activePage={"AboutYou"} />

            <div className={`about-others__contacts-wrapper margin__bottom--xs`} id="about-others__contacts-wrapper">
                {contacts &&
                    contacts.filter(item => item.completed === true).map((contact, i) =>
                        <div key={i} className={`about-others__contact margin__bottom--xxs padding__top-bottom--s padding__left-right--xs`}>
                            <IconEdit className={`about-others__contact__edit`} onClick={() => editContact(contact, i)} name="editContact" />
                            <p className={`about-others__contact__title margin__bottom--xxs`}>Person {i === 0 ? "one" : ""}{i === 1 ? "two" : ""}{i === 2 ? "three" : ""}</p>
                            <p className={`about-others__contact__text`}>{contact.title === "Other" ? contact.otherTitle : contact.title} {contact.firstName} {contact.middleName} {contact.lastName} <br /> {contact.email}</p>
                        </div>
                    )
                }
            </div>

            {contacts &&
                contacts.map((contact, i) =>
                    <div key={i}>
                        {contact.completed === false &&
                            <PageSection sectionTitle={`About person ${i === 0 ? "one" : ""} ${i === 1 ? "two" : ""} ${i === 2 ? "three" : ""} ${i === 3 ? "four" : ""}`}>
                                <p className={`margin__bottom--xxs`}>Title</p>
                                <div className={`grid-x grid-margin-x grid-margin-y margin__bottom--s`}>
                                    <div className={'cell small-12 medium-6 large-4'}>
                                        <FormInputRadio label="Mr" name={`title-${i}`} id={`Mr-${i}`} onChange={event => handleChangeTitle(event, i)} value={"Mr"} checked={contact.title === "Mr"} />
                                    </div>
                                    <div className={'cell small-12 medium-6 large-4'}>
                                        <FormInputRadio label="Mrs" name={`title-${i}`} id={`Mrs-${i}`} onChange={event => handleChangeTitle(event, i)} value={"Mrs"} checked={contact.title === "Mrs"} />
                                    </div>
                                    <div className={'cell small-12 medium-6 large-4'}>
                                        <FormInputRadio label="Miss" name={`title-${i}`} id={`Miss-${i}`} onChange={event => handleChangeTitle(event, i)} value={"Miss"} checked={contact.title === "Miss"} />
                                    </div>
                                    <div className={'cell small-12 medium-6 large-4'}>
                                        <FormInputRadio label="Ms" name={`title-${i}`} id={`Ms-${i}`} onChange={event => handleChangeTitle(event, i)} value={"Ms"} checked={contact.title === "Ms"} />
                                    </div>
                                    <div className={'cell small-12 large-4'}>
                                        <FormInputRadio label="Other" name={`title-${i}`} id={`Other-${i}`} onChange={event => handleChangeTitle(event, i)} value={"Other"} checked={contact.title === "Other"} />
                                    </div>
                                </div>
                                {contact.title === "Other" &&
                                    <FormInputText label="Enter your title" name={`otherTitle-${i}`} onChange={event => handleChangeOtherTitle(event, i)} value={contact.otherTitle || ''} />
                                }
                                <FormInputText label="First name" name={`firstName-${i}`} onChange={event => handleChangeFirstName(event, i)} value={contact.firstName || ''} required validateRule={NotEmptyString} validationError={"Please enter your first name"} />
                                <FormInputText label="Middle name" name={`middleName-${i}`} onChange={event => handleChangeMiddleName(event, i)} value={contact.middleName || ''} />
                                <FormInputText label="Last name" name={`lastName-${i}`} onChange={event => handleChangeLastName(event, i)} value={contact.lastName || ''} required validateRule={NotEmptyString} validationError={"Please enter your last name"} />
                                <FormInputText label="Email address" name={`email-${i}`} onChange={event => handleChangeEmail(event, i)} value={contact.email || ''} required validateRule={ValidateEmail} validationError={"Please enter a valid email"} />
                                {contacts.length < 4 &&
                                    <div className={`about-others__add-contact-button__wrapper`}>
                                        <button className={`about-others__add-contact-button__button`} onClick={() => addContact(i)} title="Add a contact" name="addContactBtn">+</button>
                                        <p className={`about-others__add-contact-button__text`}>Add Person (max: 4)</p>
                                    </div>
                                }
                                {contacts.length === 4 &&
                                    <p >You've reached the maximum of four people per request.</p>
                                }

                            </PageSection>
                        }
                    </div>
                )
            }

            <ErrorMessage errorMessages={error}/>



            <div className={`about-others__button-wrapper padding__bottom--l`}>
                <ButtonSecondary text="Back" onClick={back} />
                <PrimaryButton text={"Complete"} onClick={complete} name={"completeBtn"} />
            </div>

        </div>
    </>
}