export const PropertyOwnerCount = [
    {
        text: "1",
        value: "1"
    },
    {
        text: "2",
        value: "2"
    },
    {
        text: "3",
        value: "3"
    }
]

export const FinancialChargeCount = [
    {
        text: "1",
        value: "1"
    },
    {
        text: "2",
        value: "2"
    },
    {
        text: "3",
        value: "3"
    }
]
