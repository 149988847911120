import AboutYou from './pages/AboutYou';
import PropertyDetails from './pages/PropertyDetails';
import PropertyDetailsForm from './pages/PropertyDetailsForm';
import YourQuote from './pages/YourQuote';
import TermsAndConditions from './pages/TermsAndConditions';
import PropertyOwners from './pages/PropertyOwners';
import AboutOthers from './pages/AboutOthers';
import Success from './pages/Success';
import {Router} from '@reach/router';
import Header from './symbols/app/header/Header'
import {FormContextProvider} from './context/FormContext'

function App() {


  return (
    <>
      
      <FormContextProvider>
        <Header/>
        <Router primary={false}>
          <PropertyDetails path='/'/>
          <PropertyDetailsForm path='/propertyDetails'/>
          <YourQuote path='/yourQuote/:quoteId'/>
          <TermsAndConditions path='/termsAndConditions/:quoteId/:includeContactDetails'/>
          <AboutYou path='/aboutYou'/>
          <PropertyOwners path='/propertyOwners'/>
          <AboutOthers path="aboutOthers"/>
          <Success path='/success'/>
        </Router>
      </FormContextProvider>
    </>
  );
}

export default App;
