import React, { useContext } from 'react';
import {navigate} from '@reach/router';
import {FormContext} from '../../../context/FormContext';
import PrintLogo from '../../../images/brm-legal-print-logo.png';

export default function Header({className}) {
	const {setSaleType,clearAllValues} = useContext(FormContext);

	function navigateHome(){
		setSaleType("")
		clearAllValues()
		navigate(`/`)
	}

	return <div className={`header ${className ? className : ''}`}>
		<div>
			<div>
				<div className={`header__logo-wrapper`} onClick={()=>navigateHome()}>
				</div>
				<div className={`header__print-logo-wrapper`}>
				</div>
			</div>
		</div>
	</div>
}
