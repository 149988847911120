import React, {useEffect, useState} from "react";
import {navigate} from "@reach/router";

export const FormContext = React.createContext();

export function FormContextProvider(props) {
    let [contacts, setContacts] = useState([]);
    let [canNavigateToPropertyDetails, setCanNavigateToPropertyDetails] = useState(true);
    let [canNavigateToYourQuote, setCanNavigateToYourQuote] = useState(false);
    let [canNavigateToAboutYou, setCanNavigateAboutYou] = useState(false);
    let [saleType, setSaleType] = useState("");
    let [buyingHoldType, setBuyingHoldType] = useState();
    let [sellingHoldType, setSellingHoldType] = useState();
    let [values, setValues] = useState({});
    let [quoteId, setQuoteId] = useState("");
    let [isNewBuild, setIsNewBuild] = useState('');
  

    const handleChange = (event) => {
        event.persist();
        setValues(values => ({ ...values, [event.target.name]: event.target.value }));
    };

    const handleCheckboxChange = (event) => {
        event.persist();
        setValues(values => ({ ...values, [event.target.name]: event.target.checked }));
    }

    const clearAllValues = () => {
        setValues({});
        setBuyingHoldType();
        setSellingHoldType();
        setQuoteId("");
        setCanNavigateToYourQuote(false);
        setCanNavigateAboutYou(false);
        setIsNewBuild(false);
    }

    const exportContacts = () =>{
        let items = [...contacts];

        items.forEach(contact => {
            if(contact.title === "Other"){
                contact.title = contact.otherTitle
            }
        });
        return items;
    }

    


    return <FormContext.Provider value={{
        contacts: contacts,   
        setContacts:(contacts)=> setContacts(contacts),
        canNavigateToPropertyDetails: canNavigateToPropertyDetails,
        setCanNavigateToPropertyDetails:(canNavigateToPropertyDetails)=> setCanNavigateToPropertyDetails(canNavigateToPropertyDetails),
        canNavigateToYourQuote: canNavigateToYourQuote,
        setCanNavigateToYourQuote:(canNavigateToYourQuote)=> setCanNavigateToYourQuote(canNavigateToYourQuote),
        canNavigateToAboutYou: canNavigateToAboutYou,
        setCanNavigateAboutYou:(canNavigateToAboutYou)=> setCanNavigateAboutYou(canNavigateToAboutYou),
        saleType: saleType,   
        setSaleType:(saleType)=> setSaleType(saleType),
        buyingHoldType: buyingHoldType,   
        setBuyingHoldType:(buyingHoldType)=> setBuyingHoldType(buyingHoldType),
        sellingHoldType: sellingHoldType,   
        setSellingHoldType:(sellingHoldType)=> setSellingHoldType(sellingHoldType),
        isNewBuild: isNewBuild,
        setIsNewBuild: (isNewBuild) => setIsNewBuild(isNewBuild),
        quoteId: quoteId,   
        setQuoteId:(quoteId)=> setQuoteId(quoteId),
        values: values,
        handleChange,
        handleCheckboxChange,
        clearAllValues,
        exportContacts,
    }} {...props}/>
}