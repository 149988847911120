import styles from './LoadingIndicator.module.css'
import React from 'react';

export default function LoadingIndicator({modal}) {
	return <div className="loading-indicator">
		<svg className="loading-indicator__circular" viewBox="25 25 50 50">
			<circle className="loading-indicator__path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10"/>
		</svg>
  </div>
}
