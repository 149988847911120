import React from 'react';

export default function PageSection({className, sectionTitle, smallGutter, children}) {

	return <div className={`${className ? className : ''} page-section__wrapper ${smallGutter === true ? "page-section__wrapper--small-gutters" : ''}`} >
		{sectionTitle &&
			<h2>{sectionTitle}</h2>
		}
		{children}
	</div>
}
