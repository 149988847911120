import React from 'react';

export default function FormInputCheckbox({labelClassName, className, id, name, label, ...other}) {
	return <div className={`input-checkbox__wrapper ${className ? className : ''}`}>
		<input className={`input-checkbox__input`} type="checkbox" name={name} id={id} {...other}/>
		<span className={`input-checkbox-custom`}/>
		<label className={`input-checkbox__label ${labelClassName}`} htmlFor={id}>
			{label}
		</label>
	</div>
}
