import React, {useEffect} from 'react';

export default function ErrorMessage({errorMessages}) {
    const fieldRef = React.useRef(null);

	useEffect(() => {
        if (fieldRef.current) {
			fieldRef.current.scrollIntoView({
				behavior: "smooth",
			  });
		  }
    },[]);

	return <>
    {errorMessages &&
        <div className={`error-message__wrapper`}>
            <div className={`error-message__icon`}>
                <p className={`error-message__icon__text`}>i</p>
            </div>
            <div className={`error-message__text-wrapper`}>
                {errorMessages &&
                    errorMessages.map((text, i) =>
                            <p key={i} className={`error-message__text`}>{text}</p>
                        ) 
                }
            </div>
        </div>
    }
    </>
}