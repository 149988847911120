import React, { useContext, useEffect, useState } from 'react';
import Title from '../symbols/app/pageTitle/Title';
import PageIndication from '../symbols/app/pageIndication/PageIndication';
import PageSection from '../symbols/app/pageSection/PageSection';
import { FormContext } from '../context/FormContext';
import FormInputRadio from '../symbols/app/form/FormInputRadio';
import FormInputText from '../symbols/app/form/FormInputText';
import FormSelect from '../symbols/app/form/FormSelect';
import { PropertyOwnerCount, FinancialChargeCount } from '../data/SelectData';
import {
    NotEmptyString,
    ValidatePostcode,
} from '../modules/Validation';
import PrimaryButton from '../symbols/app/button/ButtonPrimary';
import ButtonSecondary from '../symbols/app/button/ButtonSecondary';
import { httpPost } from '../modules/backend';
import { navigate } from '@reach/router';
import ErrorMessage from '../symbols/app/error/errorMessage';
import LoadingModal from '../symbols/app/loading/LoadingModal';
import CheckBox from "../symbols/app/form/FormInputCheckbox";

export default function PropertyDetailsForm() {
    const {
        setCanNavigateToPropertyDetails,
        saleType,
        buyingHoldType,
        setBuyingHoldType,
        sellingHoldType,
        setSellingHoldType,
        isNewBuild,
        setIsNewBuild,
        values,
        handleChange,
        setQuoteId
    } = useContext(FormContext);
    const [error, setError] = useState();
    const [canComplete, setCanComplete] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const handleChangeBuyingHoldType = (event) => {
        event.persist();
        setBuyingHoldType(event.target.value);

    };

    const handleChangeSellingHoldType = (event) => {
        event.persist();
        setSellingHoldType(event.target.value);

    };

    function getHoldType(holdType) {
        switch (holdType) {
            case "Freehold":
                return 0
                break;
            case "Leasehold":
                return 1
                break;
            case "LeaseholdFlat":
                return 2
                break;
            default:
                return 0
        }
    }

    async function onFormSubmit(event) {
        if (event) event.preventDefault();

        if (validate()) {
            //set defaults if not set
            setSubmitting(true)

            var sellingDetails = null;
            var buyingDetails = null;

            if (saleType === "Buying" || saleType === "BuyingAndSelling") {
                values.buyingHoldType = buyingHoldType;

                buyingDetails = {
                    holdType: getHoldType(values.buyingHoldType),
                    salePrice: parseInt(values.buyingSalePrice),
                    address: {
                        line1: values.buyingAddressLine1,
                        line2: values.buyingAddressLine2,
                        city: values.buyingAddressCity,
                        county: values.buyingAddressCounty,
                        postcode: values.buyingAddressPostcode
                    },
                    isNewBuild: isNewBuild
                }
            }
            if (saleType === "Selling" || saleType === "BuyingAndSelling") {
                values.sellingHoldType = sellingHoldType;
                if (!values.sellingFinancialChargeCount) {
                    values.sellingFinancialChargeCount = FinancialChargeCount[0].value;
                }

                sellingDetails = {
                    holdType: getHoldType(values.sellingHoldType),
                    salePrice: parseInt(values.sellingSalePrice),
                    address: {
                        line1: values.sellingAddressLine1,
                        line2: values.sellingAddressLine2,
                        city: values.sellingAddressCity,
                        county: values.sellingAddressCounty,
                        postcode: values.sellingAddressPostcode
                    }
                }
            }
            //create object for post
            var postObject = {
                purchasingDetails: buyingDetails,
                saleDetails: sellingDetails
            }


            await httpPost('quoteEnquiry', postObject)
                .then(async function (response) {
                    if (response.data.successful === true) {
                        setQuoteId(response.data.data)
                        navigate(`/yourQuote/${response.data.data}`)
                    } else {
                        setError(response.data.error);
                        setSubmitting(false)
                    }

                })
                .catch(function (error) {
                    setError(["Could not get quote at this time. Please try again."]);
                    setSubmitting(false)

                });
        }
    }

    function validate() {
        setError();
        var foundError = false;
        var errorMessages = [];

        if ((saleType === "Buying" || saleType === "BuyingAndSelling") && ((!NotEmptyString(isNewBuild)))) {
            errorMessages.push("Please choose whether this is a new build property.");
            foundError = true;
        }

        if ((saleType === "Buying" || saleType === "BuyingAndSelling") && (!NotEmptyString(values.buyingHoldType))) {
            errorMessages.push("Please choose a freehold or leasehold in the purchase details section.");
            foundError = true;
        }

        if ((saleType === "Buying" || saleType === "BuyingAndSelling") && (!values.buyingSalePrice || !NotEmptyString(values.buyingSalePrice))) {
            errorMessages.push("Please add a sale price in the purchase details section.");
            foundError = true;
        }

        if ((saleType === "Buying" || saleType === "BuyingAndSelling") && (!values.buyingAddressLine1 || !NotEmptyString(values.buyingAddressLine1))) {
            errorMessages.push("Please add address line 1 in the purchase details section.");
            foundError = true;
        }

        if ((saleType === "Buying" || saleType === "BuyingAndSelling") && (!values.buyingAddressCity || !NotEmptyString(values.buyingAddressCity))) {
            errorMessages.push("Please add city in the purchase details section.");
            foundError = true;
        }

        if ((saleType === "Buying" || saleType === "BuyingAndSelling") && (!values.buyingAddressCounty || !NotEmptyString(values.buyingAddressCounty))) {
            errorMessages.push("Please add county in the purchase details section.");
            foundError = true;
        }

        if ((saleType === "Buying" || saleType === "BuyingAndSelling") && (!values.buyingAddressPostcode || !ValidatePostcode(values.buyingAddressPostcode))) {
            errorMessages.push("Please add a valid postcode in the purchase details section.");
            foundError = true;
        }

        if ((saleType === "Selling" || saleType === "BuyingAndSelling") && (!NotEmptyString(values.sellingHoldType))) {
            errorMessages.push("Please choose a freehold or leasehold in the sale details section.");
            foundError = true;
        }

        if ((saleType === "Selling" || saleType === "BuyingAndSelling") && (!values.sellingSalePrice || !NotEmptyString(values.sellingSalePrice))) {
            errorMessages.push("Please add a sale price in the sale details section.");
            foundError = true;
        }

        if ((saleType === "Selling" || saleType === "BuyingAndSelling") && (!values.sellingAddressLine1 || !NotEmptyString(values.sellingAddressLine1))) {
            errorMessages.push("Please add address line 1 in the sale details section.");
            foundError = true;
        }

        if ((saleType === "Selling" || saleType === "BuyingAndSelling") && (!values.sellingAddressCity || !NotEmptyString(values.sellingAddressCity))) {
            errorMessages.push("Please add city in the sale details section.");
            foundError = true;
        }

        if ((saleType === "Selling" || saleType === "BuyingAndSelling") && (!values.sellingAddressCounty || !NotEmptyString(values.sellingAddressCounty))) {
            errorMessages.push("Please add county in the sale details section.");
            foundError = true;
        }

        if ((saleType === "Selling" || saleType === "BuyingAndSelling") && (!values.sellingAddressPostcode || !ValidatePostcode(values.sellingAddressPostcode))) {
            errorMessages.push("Please add a valid postcode in the sale details section.");
            foundError = true;
        }

        if (errorMessages.length > 0) {
            setError([...errorMessages]);
        }
        return !foundError;
    }

    function checkCanSubmit() {
        if (saleType === "Buying"){
            setCanComplete(checkBuying());
        }
        if (saleType === "Selling"){
            setCanComplete(checkSelling());
        }
        if(saleType === "BuyingAndSelling"){
            if(checkBuying() && checkSelling()){
                setCanComplete(true);
            }else{
                setCanComplete(false); 
            }
        }
    }

    function checkBuying(){
        if (buyingHoldType && values.buyingSalePrice && values.buyingAddressLine1 && values.buyingAddressCity && values.buyingAddressCounty && values.buyingAddressPostcode && NotEmptyString(isNewBuild)) {
            return true;
        }
        return false;
    }

    function checkSelling(){
        if (sellingHoldType && values.sellingSalePrice && values.sellingAddressLine1 && values.sellingAddressCity && values.sellingAddressCounty && values.sellingAddressPostcode) {
            return true
        }
        return false;
    }

    function navigateBack() {
        navigate('/')
    }

    useEffect(() => {
        checkCanSubmit();
    }, [
        buyingHoldType,
        sellingHoldType,
        values.buyingSalePrice,
        values.buyingAddressLine1,
        values.buyingAddressCity,
        values.buyingAddressCounty,
        values.buyingAddressPostcode,
        values.sellingSalePrice,
        values.sellingAddressLine1,
        values.sellingAddressLine2,
        values.sellingAddressCity,
        values.sellingAddressCounty,
        values.sellingAddressPostcode,
        isNewBuild
    ]);


    async function handleChangeNewBuild(value) {
        setIsNewBuild(value);
    }

    useEffect(() => {
        if(saleType === "") {
            navigate('/');
        }else{
            setCanNavigateToPropertyDetails(true) 
        }
    }, [])

    return <>
        {submitting &&
            <LoadingModal/>
        }
        
        <div className={`property-details-form__content padding__left-right--xs margin__left-right--auto`}>
            <Title />
            <PageIndication activePage={"PropertyDetails"} />
            <form onSubmit={onFormSubmit}>

                {(saleType === "Selling" || saleType === "BuyingAndSelling") &&
                    <PageSection sectionTitle={"Sale Details"}>
                        <FormInputText label="Address line 1" name="sellingAddressLine1" onChange={handleChange} value={values.sellingAddressLine1 || ''} required validateRule={NotEmptyString} validationError={"Please enter line 1 of the sale address"} />
                        <FormInputText label="Address line 2" name="sellingAddressLine2" onChange={handleChange} value={values.sellingAddressLine2 || ''} />
                        <FormInputText label="City" name="sellingAddressCity" onChange={handleChange} value={values.sellingAddressCity || ''} required validateRule={NotEmptyString} validationError={"Please enter the city of the sale address"} />
                        <FormInputText label="County" name="sellingAddressCounty" onChange={handleChange} value={values.sellingAddressCounty || ''} required validateRule={NotEmptyString} validationError={"Please enter the county of the sale address"} />
                        <FormInputText label="Postcode" name="sellingAddressPostcode" onChange={handleChange} value={values.sellingAddressPostcode || ''} required validateRule={ValidatePostcode} validationError={"Please enter a valid postcode"} />

                        <FormInputText label="Agreed sale price" name="sellingSalePrice" type="number" onChange={handleChange} value={values.sellingSalePrice || ''} required validateRule={NotEmptyString} validationError={"Please enter a sale price"} prefix={"£"} />
                        <div className={`grid-x grid-margin-x grid-margin-y  property-details-form__radio-group-wrapper`}>
                            <div className={'cell small-12 medium-4'}>
                                <FormInputRadio className={`Selling_Hold_Type_Radio`} label="Freehold house" name="sellingHoldType" id={"sellingFreehold"} onChange={handleChangeSellingHoldType} value={"Freehold"} checked={sellingHoldType === "Freehold"} />
                            </div>
                            <div className={'cell small-12 medium-4'}>
                                <FormInputRadio className={`Selling_Hold_Type_Radio`} label="Leasehold house" name="sellingHoldType" id={"sellingLeaseholdHouse"} onChange={handleChangeSellingHoldType} value={"Leasehold"} checked={sellingHoldType === "Leasehold"} />
                            </div>
                            <div className={'cell small-12 medium-4'}>
                                <FormInputRadio className={`Selling_Hold_Type_Radio`} label="Leasehold flat" name="sellingHoldType" id={"sellingLeaseholdFlat"} onChange={handleChangeSellingHoldType} value={"LeaseholdFlat"} checked={sellingHoldType === "LeaseholdFlat"} />
                            </div>
                        </div>
                    </PageSection>
                }

                {(saleType === "Buying" || saleType === "BuyingAndSelling") &&
                    <PageSection sectionTitle={"Purchase Details"}>
                        <FormInputText label="Address line 1" name="buyingAddressLine1" onChange={handleChange} value={values.buyingAddressLine1 || ''} required validateRule={NotEmptyString} validationError={"Please enter line 1 of the purchasing address"} />
                        <FormInputText label="Address line 2" name="buyingAddressLine2" onChange={handleChange} value={values.buyingAddressLine2 || ''} />
                        <FormInputText label="City" name="buyingAddressCity" onChange={handleChange} value={values.buyingAddressCity || ''} required validateRule={NotEmptyString} validationError={"Please enter the city of the purchasing address"} />
                        <FormInputText label="County" name="buyingAddressCounty" onChange={handleChange} value={values.buyingAddressCounty || ''} required validateRule={NotEmptyString} validationError={"Please enter the county of the purchasing address"} />
                        <FormInputText label="Postcode" name="buyingAddressPostcode" onChange={handleChange} value={values.buyingAddressPostcode || ''} required validateRule={ValidatePostcode} validationError={"Please enter a valid postcode"} />
                        <FormInputText label="Agreed sale price" name="buyingSalePrice" type="number" onChange={handleChange} value={values.buyingSalePrice || ''} required validateRule={NotEmptyString} validationError={"Please enter a sale price"} prefix={"£"} />

                        <div className={`grid-x grid-margin-x grid-margin-y property-details-form__radio-group-wrapper__NewBuild`}>
                            <div className={'cell small-12 medium-6'}>
                                <FormInputRadio className={'Buying_New_Build_Radio'} label="This is NOT a new build property" name="buyingNewBuild" id="buyingNotNewBuild" onChange={() => handleChangeNewBuild(false)} value={"false"} checked={isNewBuild === false} />
                            </div>
                            <div className={'cell small-12 medium-6'}>
                                <FormInputRadio className={'Buying_New_Build_Radio'} label="This is a new build property" name="buyingNewBuild" id="buyingNewBuild" onChange={() => handleChangeNewBuild(true)} value={"true"} checked={isNewBuild === true} />
                            </div>
                        </div>

                        <hr className={'page-section__rule'}/>

                        <div className={`grid-x grid-margin-x grid-margin-y property-details-form__radio-group-wrapper__HoldType`}>
                            <div className={'cell small-12 medium-4'}>
                                <FormInputRadio className={`Buying_Hold_Type_Radio`} label="Freehold house" name="buyingHoldType" id={"buyingFreehold"} onChange={handleChangeBuyingHoldType} value={"Freehold"} checked={buyingHoldType === "Freehold"} />
                            </div>
                            <div className={'cell small-12 medium-4'}>
                                <FormInputRadio className={`Buying_Hold_Type_Radio`} label="Leasehold house" name="buyingHoldType" id={"buyingLeaseholdHouse"} onChange={handleChangeBuyingHoldType} value={"Leasehold"} checked={buyingHoldType === "Leasehold"} />
                            </div>
                            <div className={'cell small-12 medium-4'}>
                                <FormInputRadio className={`Buying_Hold_Type_Radio`} label="Leasehold flat" name="buyingHoldType" id={"buyingLeaseholdFlat"} onChange={handleChangeBuyingHoldType} value={"LeaseholdFlat"} checked={buyingHoldType === "LeaseholdFlat"} />
                            </div>
                        </div>
                    </PageSection>
                }

                {error &&
                    error.map((text, i) =>
                        <div key={i} className={`property-details-form__error-wrapper`}>
                            <p className={`property-details-form__error`}>{text}</p>
                        </div>
                    )
                }

                {saleType !== "" &&
                    <div className={`property-details-form__footer-button-wrapper`}>
                        <ButtonSecondary text="Back" onClick={navigateBack} type="button" />
                        <PrimaryButton name="submitBtn" text={"Next"} type="submit" disabled={!canComplete}/>
                    </div>
                }
            </form>
        </div>
    </>
}