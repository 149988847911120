import React, { useContext, useEffect, useState } from 'react';
import Title from '../symbols/app/pageTitle/Title';
import PageIndication from '../symbols/app/pageIndication/PageIndication';
import { FormContext } from '../context/FormContext';
import { httpGet } from '../modules/backend';
import PageSection from '../symbols/app/pageSection/PageSection';
import QuoteItem from '../symbols/app/quote/QuoteItem';
import CheckBox from '../symbols/app/form/FormInputCheckbox';
import ButtonPrimary from '../symbols/app/button/ButtonPrimary';
import ButtonSecondary from '../symbols/app/button/ButtonSecondary';
import ButtonLink from '../symbols/app/button/ButtonLink';
import { navigate } from '@reach/router';

export default function YourQuote({ quoteId }) {
    const { setCanNavigateToYourQuote } = useContext(FormContext);
    const [error, setError] = useState(false);
    const [purchaseDetailsItems, setPurchaseDetailsItems] = useState();
    const [purchaseDetailsTotal, setPurchaseDetailsTotal] = useState();
    const [saleDetailsItems, setSaleDetailsItems] = useState();
    const [saleDetailsTotal, setSaleDetailsTotal] = useState();
    const [grandTotal, setGrandTotal] = useState();
    const [acceptTC, setAcceptTC] = useState(false);
    const [loadComplete, setLoadComplete] = useState(false);

    async function getQuote() {
        await httpGet(`QuoteEnquiry?quoteId=${quoteId}`)
            .then(async function (result) {
                var quote = result.data;
                setPurchaseDetailsItems(quote.purchaseDetails.items)
                setPurchaseDetailsTotal(quote.purchaseDetails.total)
                setSaleDetailsItems(quote.saleDetails.items)
                setSaleDetailsTotal(quote.saleDetails.total)
                setGrandTotal(quote.grandTotal)
                setLoadComplete(true)
            }).catch(function () {
                setError(true);
            });
    }


    const handleCheckboxChange = (event) => {
        event.persist();
        setAcceptTC(event.target.checked);
    }

    function navigateBack() {
        navigate('/propertyDetails')
    }

    function navigateTandC() {
        navigate(`/termsAndConditions/${quoteId}/${false}`)
    }

    useEffect(() => {
        setCanNavigateToYourQuote(true)
        getQuote()
    }, []);

    async function acceptQuote() {
        navigate('/aboutYou')
    }

    return <div className={`quote__content margin__left-right--auto padding__left-right--xs`}>
        <Title />
        <PageIndication activePage={"YourQuote"} />
        {loadComplete &&
        
        <>
        
        {saleDetailsItems && saleDetailsItems.length > 0 &&
            <PageSection sectionTitle={"Selling fees"} smallGutter={false}>
                {saleDetailsItems &&
                    saleDetailsItems.map((item, i) =>
                        <QuoteItem key={i} title={item.title} prefix={"£"} amount={item.amount} />
                    )}
                <QuoteItem className={`quote__total`} dark={true} title={"Total"} prefix={"£"} amount={saleDetailsTotal} />
            </PageSection>
        }


        {purchaseDetailsItems && purchaseDetailsItems.length > 0 &&
            <PageSection sectionTitle={"Buying fees"} smallGutter={false}>
                {purchaseDetailsItems &&
                    purchaseDetailsItems.map((item, i) =>
                        <QuoteItem key={i} title={item.title} prefix={"£"} amount={item.amount} />
                    )}
                <QuoteItem className={`quote__total`} dark={true} title={"Total"} prefix={"£"} amount={purchaseDetailsTotal} />
            </PageSection>
        }

        <QuoteItem title={"Grand total"} prefix={"£"} amount={grandTotal} dark={true} />

        <p>We hope however that you will find our quote competitive and look forward to hearing from you on this in due course.</p>
        <p>You will have the opportunity to calculate your stamp duty at the end of this process.</p>
        <p class="hide-on-print">Please note that this is an indicative quote, which maybe subject to change</p>


        <div class="show-on-print-only">
            <p>Please note that this is only an indicative quote of the costs that may be involved in your completion of the matter.</p>
            <p>We shall do all that we can to ensure that the final amount paid is consistent with this quote, however, there are occasions when factors outside our control mean that the eventual amount paid may be more than this quote. This may be due to a number of factors such as local price variances for the costs of searches, or additional searches and enquiries being required for your particular matter.</p>
            <p>If you are purchasing a property and have to pay Stamp Duty Land Tax you should be aware that we have no control over the amount of stamp duty that you will have to pay, or whether you have to pay a penalty or not. In some cases a surcharge penalty of 3% is charged and this will depend on your individual specific circumstances.</p>
            <p>If you are purchasing or selling a leasehold property then additional charges are typically paid to a landlord or management fee for details of the rent and service charge account, or for a sellers management pack.</p>
            <p>In addition to the above, where your property is affected by more than one legal charge, we charge an additional sum for the work involved in dealing with each subsequent legal charge affecting the property. Typically the amount that we charge is an additional amount of £75 plus VAT for the discharge of each subsequent legal charge that may affect the property, however it may be that in some cases the work involved is more complex and in this case the cost will be higher and based on the amount of time taken to deal with the charge.</p>
            <p>Where you ask us to read and comment on specialist reports such as a building or structural survey we will charge an additional amount reflecting the time taken for us to read, consider and comment on such a report. Typically this will be £75 plus VAT per report, but may be higher if the report is lengthy and complex.</p>
            <p>We also charge a fee of £36.00 for each telegraphic transfer of funds that we make on your behalf and this means that if you require the balance of funds paid to you by telegraphic transfer, or we have to discharge a mortgage by telegraphic transfer an additional fee of £36 will apply for each telegraphic transfer that we make on your behalf.</p>
            <p>I hope however that you will find this quote competitive and look forward to hearing from you on this in due course.</p>
            <p>Yours faithfully,</p>
            <p>BRM Legal</p>
        </div>



        <div className={`quote__footer padding__top-bottom--m`}>
            <div className={`quote__footer__checkbox__wrapper`}>
                <CheckBox onChange={handleCheckboxChange} value={acceptTC} name={"acceptCheckbox"}/>
                <p className={`quote__footer__checkbox__text`}>I accept the terms & conditions</p>
            </div>
            <ButtonLink name="termsConditionsBtn" text="View terms and conditions" onClick={navigateTandC}/>

            <div className={`quote__footer__button-wrapper margin__top--s margin__bottom--l`}>
                <ButtonSecondary text="Back" onClick={navigateBack} />
                <ButtonPrimary text="Accept quote" disabled={!acceptTC} onClick={() => acceptQuote()} name="acceptBtn"/>
            </div>

            {error &&
                error.map((text, i) =>
                    <div key={i} className={`quote__error-wrapper`}>
                        <span className={`quote__error`}>{text}</span>
                    </div>
                )
            }


        </div>

        </>
        }
    </div>
}