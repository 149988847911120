import React, { useState } from 'react';

export default function FormInputText({className, label, name, required,toolTipText,validateRule,validationError,prefix, ...other}) {
	const [valid, setValid] = useState();

	function validate(e){
		if(validateRule){
			setValid(validateRule(e.target.value))
		}
	}


	return <div className={`${className ? className : ''}`}>
		<label className={`input-text__label`} htmlFor={name}>
			{required ? `${label} *` : label}
		</label>
		<div className={`input-text__wrapper`}>
			{prefix && prefix !== "" &&
				<div className={`input-text__prefix`}>{prefix}</div>
			}
			<input className={`input-text__input ${valid === false ? "input-text__error" : ""} ${prefix  ? "input-text__with-prefix" : ""}`} type="text" name={name} required={required} onBlur={validate} {...other}/>
			{valid === false && validationError !== "" &&
				<p className={`input-text__validation-error`}>{validationError}</p>
			}
		</div>
	</div>
}
