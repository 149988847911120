import React from 'react';
import Tick from '../images/tick-icon.svg';
import Phone from '../images/phone-icon.svg';
import Email from '../images/email-icon.svg';

export default function Success ({ }) {

    return <div className={`success__content`}>

        <div className={'success__text-wrapper margin__left-right--auto'}>
            <div className={'success__tick'}></div>
            <h1>Thank you</h1>
            <p>We've received your information and will be in touch shortly.</p>
            <p>If you don't hear from us within two working days or need to contact us, you can contact us at:</p>
        </div>
        
        <div className={"grid-x success__contact-info margin__left-right--auto padding__top-bottom--m padding__left-right--m textAlign--left success__brm-address-details"}>
            <div className={'cell small-12 medium-6'}>
                <p className={"success__contact-info__text margin__bottom--xxs"}>BRM Legal</p>
                <p className={"success__contact-info__text margin__bottom--xxs"}>86 Walsgrave Road</p>
                <p className={"success__contact-info__text margin__bottom--xxs"}>Coventry CV2 4ED</p>
            </div>
            <div className={'cell small-12 medium-6'}>
                <p className={"success__contact-info__text margin__bottom--xxs"}><img src={Email} alt="Email icon"/> info@brmlegal.co.uk</p>
                <p className={"success__contact-info__text margin__bottom--xxs"}><img src={Phone} alt="Phone icon"/> 0845 838 7089</p>
            </div>
        </div>

        <div className={"grid-x success__contact-info margin__left-right--auto padding__top-bottom--m padding__left-right--m textAlign--left success__stride-address-details"}>
            <div className={'cell small-12 medium-6'}>
                <p className={"success__contact-info__text margin__bottom--xxs"}>Stride</p>
                <p className={"success__contact-info__text margin__bottom--xxs"}>2c. St. Michaels Road</p>
                <p className={"success__contact-info__text margin__bottom--xxs"}>Coventry, CV2 4EJ</p>
            </div>
            <div className={'cell small-12 medium-6'}>
                <p className={"success__contact-info__text margin__bottom--xxs"}><img src={Email} alt="Email icon"/> arfan@stridelegal.co.uk</p>
                <p className={"success__contact-info__text margin__bottom--xxs"}><img src={Phone} alt="Phone icon"/> 0845 838 7089</p>
            </div>
        </div>


        <div className={` margin__left-right--auto success__stampDuty__Wrapper`}>
            <p className={"success__stampDuty__Text"}>To find out the cost of your stamp duty please visit this site <a className={"success__stampDuty__Text-Link"} href="https://www.tax.service.gov.uk/calculate-stamp-duty-land-tax/#/intro" target="_blank">gov.uk/calculate-stamp-duty-land-tax</a></p>
        </div>
        <div className={'success__footer'}>
            <div className="success__footer-background-image"></div>
        </div>
        
    </div>
}