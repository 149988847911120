import React from 'react';

export default function FormInputRadio({labelClassName, className, id, name, label, ...other}) {
	return <div className={`input-radio__wrapper`}>

		<input className={`input-radio__input ${className ? className : ''}`} type="radio" name={name} id={id} {...other}/>
		
		<label className={`input-radio__label ${labelClassName ? labelClassName: ''}`} htmlFor={id}>
			{label}
		</label>
	</div>
}
