import React , { useContext } from 'react';
import { navigate } from '@reach/router';
import { FormContext } from '../../../context/FormContext';

export default function PageIndication({activePage}) {
	const { canNavigateToPropertyDetails, canNavigateToYourQuote,canNavigateToAboutYou, quoteId } = useContext(FormContext)

	function navigateToAboutYou(){
		if(canNavigateToAboutYou){
			navigate('/aboutYou')
		}
		
	}

	function navigateToPropertyDetails(){
		if(canNavigateToPropertyDetails){
			navigate('/')
		}
	}

	function navigateToYourQuote(){
		if(canNavigateToYourQuote){
			navigate(`/yourQuote/${quoteId}`)
		}
	}

	return <div className={`page-indication margin__top-bottom--s`}>
		<div className={`page-indication__wrapper margin__left-right--auto margin__bottom--xl`}>
			<div className={`page-indication__item-wrapper`}>
				<div className={`page-indication__circle ${(activePage === "YourQuote" || activePage === "AboutYou") ? "page-indication__circle__line-blue" : "page-indication__circle__line" }  ${activePage === "PropertyDetails" ? "page-indication__circle__active" : "page-indication__circle__visited"}`} onClick={navigateToPropertyDetails}>
					<p className={`page-indication__circle__text`}>1</p>
				</div>
				<p className={`page-indication__text`}>Property details</p>
			</div>
			<div className={`page-indication__item-wrapper`}>
				<div className={`page-indication__circle page-indication__circle__line ${activePage === "AboutYou" ? "page-indication__circle__line-blue" : "page-indication__circle__line" } ${activePage === "YourQuote" ? "page-indication__circle__active"  : ""} ${activePage === "AboutYou" ? "page-indication__circle__visited"  : ""}`} onClick={navigateToYourQuote}>
					<p className={`page-indication__circle__text`}>2</p>
				</div>
				<p className={`page-indication__text`}>Your quote</p>
			</div>
			<div className={`page-indication__item-wrapper`}>
				<div className={`page-indication__circle ${activePage === "AboutYou" ? "page-indication__circle__active"  : ""}`} onClick={navigateToAboutYou}>
					<p className={`page-indication__circle__text`}>3</p>
				</div>
				<p className={`page-indication__text`}>About you</p>
			</div>
		</div>
	</div>
}
